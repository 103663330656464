<div id="sign-in-dialog" class="cadastro_endereco" id="modal-dialog">
    
	<div class="modal_header" id="modal_header" style="margin-bottom: 0;">
	  <mat-icon id="icon_header" class="icon-voltar material-icons-outlined" (click)="voltarModal(stepper)" [hidden]="btn_voltar">chevron_left</mat-icon>
	  <h3 class="modal_titulo">Cartão de crédito</h3>
	  <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
		<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
	  </button>
	</div>
  
	<div class="modal_body" id="modal_body">
	  <mat-stepper linear #stepper (selectionChange)="onStepChange($event)">
		
		<mat-step [stepControl]="firstFormGroup">

			<div style="text-align: center;">
				<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/cartao-credito.svg" alt="Pessoa segurando uma carteira e retirando um cartão de crédito." width="200px">
			</div>

			<div *ngIf="cartoesCadastrados?.length == 0">
				<div style="padding-top: 2rem; padding-bottom: 2rem;">
					<h2 class="center" style="margin: 0;">Oops, nenhum cartão cadastrado.</h2>
					<h3 class="center" style="margin: 0;">Clique no botão abaixo para cadastrar.</h3>
				</div>
			</div>

			<div class="div-button">
				<button class="btn_1 small botao" style="margin-right: 0;" (click)="avancarModal(stepper)">Adicionar novo cartão</button>
			</div>
			
			<div *ngIf="cartoesCadastrados?.length > 0">				
				<div *ngFor="let cartao of cartoesCadastrados" class="cartao">
					<mat-icon class="material-icons-outlined icon-favorito" *ngIf="cartao.Principal">favorite</mat-icon>
					<mat-icon class="material-icons-outlined icon-favorito" *ngIf="!cartao.Principal" (click)="marcarCartaoPrincipal(cartao)">favorite_border</mat-icon>
			  
					<div class="informacoes-cartao" (click)="selecionarCartao(cartao)">
						<div>{{ cartao.Apelido }}</div>
						<div>{{ cartao.Bandeira }}</div>
						<div>{{ cartao.NumeroCartao }}</div>
						<div>{{ cartao.ExpiracaoMes }} / {{ cartao.ExpiracaoAno }}</div>
					</div>
		
					<div class="icon_remover" (click)="removerCartao(cartao)">
						<span class="box1">
						<mat-icon class="material-icons-outlined">delete</mat-icon>
						</span>
					</div>
				</div>
			</div>
		</mat-step>
  
		<mat-step [stepControl]="secondFormGroup">

			<div class="bandeiras">
				<div class="cobranca">
					Faremos uma pequena cobrança com devolução automática
				</div>
				<div>
					<img width="40" height="30" *ngFor="let bandeira of bandeiras" [src]="bandeira.Imagem"
					[class.bandeira-selecionada]="!bandeira.Selecionado" [alt]="'Bandeira de cartão' + bandeira.Nome">
				</div>
			</div>
	
			<form [formGroup]="form" style="color: black;">
				<div class="form-group">
					<label>Apelido do cartão</label>
					<input ngbAutofocus type="text" class="form-control" id="apelido" name="apelido"
						placeholder="Como você quer chamar este cartão?" formControlName="Apelido">
				</div>
	
				<div class="form-group">
					<label>Nome no cartão</label>
					<input type="text" class="form-control" id="name_card_order" name="name_card_order"
						placeholder="Como está no cartão" formControlName="Nome">
				</div>
	
				<div class="form-group">
					<label>Número do cartão</label>
					<input id="cc-number" type="tel" autocomplete="cc-number" ccNumber class="form-control"
						placeholder="Apenas números" formControlName="Numero" (blur)="buscarBandeira()">
				</div>
	
				<div class="row">
					<div class="col-md-6">
						<label>Data de expiração</label>
						<div class="row">
							<div class="col-md-6 col-6" style="padding-right: 5px;">
								<div class="form-group">
									<input type="tel" id="expire_month" name="mesDataExpiracao" class="form-control" placeholder="mm"
										formControlName="Mes" mask="M0" [leadZeroDateTime]="true">
								</div>
							</div>
							<div class="col-md-6 col-6" style="padding-left: 5px;">
								<div class="form-group">
									<input type="tel" id="expire_year" name="anoDataExpiracao" class="form-control" placeholder="aaaa"
										formControlName="Ano" mask="0000" [leadZeroDateTime]="true" #ano>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-6 col-sm-12">
						<div class="form-group">
							<label>CVV</label>
							<div class="row">
								<div class="col-md-4 col-6">
									<div>
										<input type="tel" id="ccv" formControlName="Seguranca" name="ccv" class="form-control"
										placeholder="CCV" ccCVC>
									</div>
								</div>
								<div class="col-md-8 col-8">
									<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/icon_ccv.gif" width="50" height="29" alt="Cartão de crédito com destaque para o Código de Verificação (CVV) envolto por um quadrado vermelho." style="margin-right: 1rem;">
									<small>Últimos 3 digitos</small>
								</div>
							</div>
						</div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<label class="container_check">Usar esse cartão por padrão
							<input type="checkbox" formControlName="Principal" (change)="favoritar()">
							<mat-icon class="icon_favorito material-icons-outlined" [hidden]="favorito">favorite_border</mat-icon>
							<mat-icon class="icon_favorito material-icons-outlined" [hidden]="!favorito">favorite</mat-icon>
						</label>
					</div>
				</div>
	
				<div *ngIf="mensagemErro">{{ mensagemErro }}</div>
			</form>
			<button [disabled]="!cartaoValido" (click)="salvarCartao()" class="btn_1 full-width">Salvar</button>
  
		</mat-step>
  
	  </mat-stepper>
  
	</div>
  
  </div>
